











































































































import { Component, Vue, Ref } from "vue-property-decorator";
import { Route } from "vue-router";
import { Dictionary } from "vue-router/types/router";
import { register } from "@/api/patients";
import { Message } from "element-ui";

@Component({
  name: "Register",
})
export default class extends Vue {
  @Ref("password") readonly elPassword!: HTMLInputElement;
  @Ref("confirmPassword") readonly elConfirmPassword!: HTMLInputElement;
  @Ref("form") readonly elForm!: HTMLFormElement;

  private registerForm = {
    password: "",
    token: this.$route.query.patient
  };
  private confirmPassword = "";
  private passwordType = "password";
  private passwordType2 = "password";
  private loading = false;
  private formValidated = false;

  get passwordConfirmRegEx() {
    return "^" + this.registerForm.password.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') + "$";
  }

  private showPwd() {
    this.passwordType = this.passwordType === "password" ? "" : "password";
    this.$nextTick(() => {
      this.elPassword.focus();
    });
  }
  private showPwd2() {
    this.passwordType2 = this.passwordType2 === "password" ? "" : "password";
    this.$nextTick(() => {
      this.elConfirmPassword.focus();
    });
  }

  private async onRegister() {
    //console.log(this.registerForm);
    // Vérifier la validité des champs
    if (
      this.elForm.checkValidity() === false ||
      this.registerForm.password !== this.confirmPassword
    ) {
      this.formValidated = true;
    } else {
      // Inscription
      this.loading = true;
      try {
        const { data } = await register(this.registerForm);
        Message({
          message: "Votre inscription a réussie. Vous allez être redirigé à l'accueil.",
          type: "success",
          duration: 5 * 1000,
          onClose: this.onRedirectLogin,
        });
      } finally {
        this.loading = false;
      }
    }
  }

  private onRedirectLogin() {
    this.$router.push(`/login`);
  }

  mounted(){
    //console.log(this.$route.query);
  }
}
